<template>
  <div style="padding: 30px; box-sizing: border-box">
    <div style="width: 100%">
      <img
        :src="require('@/assets/image/invite.png')"
        alt=""
        style="
          width: 100%;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        "
      />
      <div
        style="
          width: 100%;
          background-color: rgb(33, 34, 68);
          padding: 30px 20px;
          box-sizing: border-box;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        "
      >
      <van-row gutter="20">
        <van-col style="margin-top: 30px; color: #fff" span="24">
          How to get ETH bond dividends?
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3" span="24">
          Investors hold USDT (Tether) and use wallets to participate in ETH bonds. The ETH blockchain pays the corresponding ETH bond interest every day.
        </van-col>
        <van-col style="margin-top: 30px; color: #fff" span="24">
          What is the value of ETH bonds?
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3" span="24">
          ETH bond interest is equivalent to USDT, 1ETH bond is equal to 1USDT (Tether)
        </van-col>
        <van-col style="margin-top: 30px; color: #fff" span="24">
          How to withdraw interest?
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3;margin-bottom: 50px;" span="24">
          Exchange ETH bonds for USDT (Tether), and it will arrive in the wallet within 24 hours.
        </van-col>
        <h3 style="margin: 0 auto;">What are ETH Bonds?</h3>
       <van-col style="margin-top: 20px; color: #7080b3" span="24">
        ETH bonds are issued by the ETH blockchain and are a common way to raise funds for cryptocurrency/blockchain projects, from which participants can receive the initial cryptocurrency generated as a return.
        </van-col>
        <van-col style="margin-top: 20px; color: #7080b3" span="24">
          Since the tokens have market value, they can be exchanged for legal currency to support the development costs of the project. The tokens issued by ETH bonds can be based on different blockchains.
        </van-col>
        <van-col style="margin-top: 20px; color: #7080b3" span="24">
          The most common ones are based on the Ethereum (ETH) and BitShares (BTS) blockchain issuance, with the blockchain providing accounting services and value consensus to achieve global issuance and circulation.
        </van-col>
        <van-col style="margin-top: 20px; color: #7080b3" span="24">
          ETH bond participants are very important to the success of a project. They will promote the blockchain project in the community and enable the tokens it generates to gain liquidity before trading begins.
        </van-col>
        <van-col style="margin-top: 20px; color: #7080b3" span="24">
          However, the participants of ETH bonds still value the potential benefits brought by project development or price appreciation after the issuance of new tokens. When new tokens are issued and listed, investors who participate in ETH bonds in the early stage have the right of first refusal.
        </van-col>
        <van-col style="margin-top: 30px; color: #fff" span="24">
          Why does ETH bond need to be anchored to Tether?
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3" span="24">
          Tether strictly adheres to the 1:1 reserve guarantee, that is, for every USDT token issued, there will be $1 in funds guaranteed in its bank account.
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3" span="24">
          Tether is a virtual currency that links cryptocurrency to the fiat currency, the U.S. dollar. Each Tether coin is symbolically linked to a government-backed fiat currency.
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3" span="24">
          Tether is a virtual currency that is stored in a foreign exchange reserve account and backed by legal tender. This method can effectively prevent the price of cryptocurrencies from fluctuating sharply.
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3" span="24">
          To ensure investors' returns, one Tether is basically worth 1 USD.
        </van-col>
        <van-col style="margin-top: 30px; color: #fff" span="24">
          How to withdraw ETH bonds?
        </van-col>
        <van-col style="margin-top: 30px; color: #7080b3" span="24">
          When the wallet USDT (Tether) balance is 0, the ETH blockchain will default to withdrawing ETH bonds.
        </van-col>
        </van-row>
        
      </div>
    </div>
  </div>

  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "invite",
  data() {
    return {
      qrUrl: "",
      invite_code: "",
      tronweb: "",
    };
  },
  methods: {
    copyLink() {
      let url = this.$api.baseUrl + '/#/home?invite_code=' + this.invite_code
      let copyInput = document.createElement('input');//创建input元素
      document.body.appendChild(copyInput);//向页面底部追加输入框
      copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
      copyInput.select();//选择input元素
      document.execCommand("Copy");//执行复制命令
      copyInput.remove();//删除动态创建的节点
      return this.$toast("复制成功");
    },
    async getTrcWallet() {
      this.tronweb = window.tronWeb;
      this.address = this.tronweb.defaultAddress.base58;
    },
    getUserInfo() {
      this.$request
        .get("/nft/scUser/getUserInfo", {
          address: sessionStorage.getItem("address"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.invite_code = res.data.result.ownCode;
            const Qrcode = require("qrcode");
            Qrcode.toDataURL(
              this.$api.baseUrl + "/#/home?invite_code=" + this.invite_code,
              (err, url) => {
                this.qrUrl = url;
              }
            );
          }
        });
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>

<style scoped>
</style>
